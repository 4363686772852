import { sessionStorageService } from '@@/utils/StorageService';
import { useUserStore } from '@@/stores/User';
import { useInAppView } from '@@/composables/useInAppView';
import { useNuxtApp } from 'nuxt/app';

export default {
  /**
   * @todo Consult cookie, user privacy settings, etc, to determine if user has consented to
   * tracking. For now, just always return true.
   */
  canTrackUser() {
    if (useInAppView() === true) {
      console.log('Not tracking user, in app view');
      return false;
    }

    return true;
  },

  canUseBraze() {
    const config = useRuntimeConfig().public;
    const route = useRoute();
    const userStore = useUserStore();

    if (!config.brazeSdkApiKey || !config.brazeSdkEndpoint) {
      console.log('Braze: Not loaded, missing API key or SDK endpoint');
      return false;
    }

    if (userStore.isGuest && !config.brazeTrackGuests) {
      console.log('Braze: Not tracking guest users');
      return false;
    }

    // No braze sessions on the homepage for guests because that's a lot of sessions
    if (userStore.isGuest && useRoute().path === '/') {
      console.log('Braze: Not tracking guest users on home page');
      return false;
    }

    // No braze sessions on certain pages (login, reg, buy) to prevent marketing popups
    if (config.brazeDisallowPaths.includes(route.path)) {
      console.log('Braze: Not tracking users on disallowed paths');
      return false;
    }

    return true;
  },

  async initializeBraze() {
    const config = useRuntimeConfig().public;

    if (!this.canUseBraze()) {
      return;
    }

    // The braze/web-sdk cannot be imported on the server side since it uses the navigator
    // object which isn't available on the server. So it is dynamically imported here when
    // initializeBraze() is called.
    const braze = await import('@braze/web-sdk');

    // Initialize Braze using the configuration values from the .env file. Note that by default
    // logging is enabled in development. In staging and production, it can be enabled from the
    // developer console by running: $nuxt.$braze.toggleLogging()
    const success = braze.initialize(config.brazeSdkApiKey, {
      allowUserSuppliedJavascript: true,
      baseUrl: config.brazeSdkEndpoint,
      enableLogging: config.isDev,
    });

    if (success) {
      const nuxtApp = useNuxtApp();
      nuxtApp.provide('braze', braze);
      console.log('Braze: Initialized successfully');
    }
    else {
      console.log('Braze: Unable to initialize Braze');
    }

    return success;
  },

  initializeGtag() {
    if (this.isGtagLoaded()) {
      return true;
    }

    const { gtagId } = useRuntimeConfig().public;

    if (!gtagId) {
      console.log('Gtag: Not loaded, missing gtagId!');
      return false;
    }

    const { initialize } = useGtag();
    initialize(gtagId);

    console.log('Gtag: Initialized successfully');

    return true;
  },

  isBrazeLoaded() {
    const { $braze } = useNuxtApp();
    return !!$braze;
  },

  /**
   * @see https://stackoverflow.com/questions/55852511/how-to-check-if-a-google-tag-is-already-loaded-on-an-html-page
   */
  isGtagLoaded() {
    let loadEvent = window?.dataLayer?.find((elem) => elem.event === 'gtm.load');

    if (!loadEvent) {
      return false; // Not even the GTM inline config has executed
    }

    if (!loadEvent['gtm.uniqueEventId']) {
      return false; // GTM inline config has ran, but main GTM js is not loaded (likely AdBlock, NoScript, URL blocking etc.)
    }

    return true; // GTM is fully loaded and working
  },

  async startBraze() {
    if (!this.isBrazeLoaded()) {
      await this.initializeBraze();
    }

    const { $braze } = useNuxtApp();

    if (!$braze) {
      return;
    }

    const userStore = useUserStore();

    try {
      if (!userStore.isGuest) {
        // If the user is not a guest then change the braze user, but only do this once, since
        // calling changeUser() multiple times is discouraged.
        const changedBrazeUser = 'changedBrazeUser';
        const hasChangedBrazeUser = sessionStorageService.getItem(changedBrazeUser);

        if (!hasChangedBrazeUser) {
          console.log(`Braze: changing user to ${userStore.user.uuid}`);
          $braze.changeUser(userStore.user.uuid);
          sessionStorageService.setItem(changedBrazeUser, true);
        }
      }

      // Automatically show in app messages _after_ changing user
      $braze.automaticallyShowInAppMessages();

      console.log('Braze: opening session');
      $braze.openSession();
    }
    catch (e) {
      // Do nothing, just try again later
    }
  },

  sendAdformTrackingPoints() {
    const cookieId = window?.Adform?._uid;
    const trackingSetupId = useRuntimeConfig().public.adformTrackingSetupId;

    // Don't make a request to the Adform tracking points endpoint if we don't have an Adform setup
    // ID, it'll just return a 404.

    if (trackingSetupId) {
      const { $api } = useNuxtApp();
      return $api.post('/user/trackingpoints', undefined, { cookieId, trackingSetupId });
    }

    return undefined;
  },
};
